// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-checkout-payment-tsx": () => import("./../src/pages/checkout/payment.tsx" /* webpackChunkName: "component---src-pages-checkout-payment-tsx" */),
  "component---src-pages-checkout-shipping-tsx": () => import("./../src/pages/checkout/shipping.tsx" /* webpackChunkName: "component---src-pages-checkout-shipping-tsx" */),
  "component---src-pages-guest-checkout-payment-tsx": () => import("./../src/pages/guestCheckout/payment.tsx" /* webpackChunkName: "component---src-pages-guest-checkout-payment-tsx" */),
  "component---src-pages-guest-checkout-shipping-tsx": () => import("./../src/pages/guestCheckout/shipping.tsx" /* webpackChunkName: "component---src-pages-guest-checkout-shipping-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newproduct-jsx": () => import("./../src/pages/newproduct.jsx" /* webpackChunkName: "component---src-pages-newproduct-jsx" */),
  "component---src-pages-privacy-tsx": () => import("./../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-profile-details-tsx": () => import("./../src/pages/profile/details.tsx" /* webpackChunkName: "component---src-pages-profile-details-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-profile-orders-index-tsx": () => import("./../src/pages/profile/orders/index.tsx" /* webpackChunkName: "component---src-pages-profile-orders-index-tsx" */),
  "component---src-pages-profile-referrals-tsx": () => import("./../src/pages/profile/referrals.tsx" /* webpackChunkName: "component---src-pages-profile-referrals-tsx" */),
  "component---src-pages-seller-tsx": () => import("./../src/pages/seller.tsx" /* webpackChunkName: "component---src-pages-seller-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-templates-category-page-tsx": () => import("./../src/templates/category-page.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */)
}

