export const isBrowser = typeof window !== "undefined";

const prefix = process.env.GATSBY_FARM_NAME;

export const getSessionState = (key: string) => {
  key = prefix + key;
  try {
    const serializedState = isBrowser ? sessionStorage.getItem(key) : null;
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.log(
      "Got error while trying to get local state \n",
      "check local-storage.ts file => getLocalState function line 1",
      error
    );
    return undefined;
  }
};

export const setSessionState = (key: string, value: any) => {
  key = prefix + key;
  try {
    const serializedState = JSON.stringify(value);
    if (isBrowser) {
      sessionStorage.setItem(key, serializedState);
    }
  } catch (error) {
    console.log(
      "Got error while trying to set local state \n",
      "check local-storage.ts file => setLocalState function line 18",
      error
    );
  }
};

export const removeSessionStateItem = (key: string) => {
  key = prefix + key;
  try {
    if (isBrowser) {
      sessionStorage.removeItem(key);
    }
  } catch (error) {
    console.log(
      `Got error while trying ${key} from remove local state \n`,
      "check local-storage.ts file => removeLocalStateItem function line 31",
      error
    );
  }
};

export const clearSessionState = () => {
  try {
    if (isBrowser) {
      sessionStorage.clear();
    }
  } catch (error) {
    console.log(
      "Got error while trying to clear local state \n",
      "check local-storage.ts file => clearLocalState function line 43",
      error
    );
  }
};
