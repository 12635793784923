//import awsconfig from '../aws-exports';
//import Amplify from 'aws-amplify';

//Amplify.configure(awsconfig);

/*export const getCustomerToken = async () => {
  try {
	 const user = await Amplify.Auth.currentUserInfo(); 
	 var tokenJson = null;
	 console.log("user: ", user);

	 if (user) {
		 const email =  user.attributes.email;  
		 const pass =  user.attributes.sub.substring(0, 13);
		  const query = `mutation {
				  customerAccessTokenCreate(
					  input: {
						email: "${email}",
						password: "${pass}"
					  }
					)
					{
						customerAccessToken {
						  accessToken
						  expiresAt
						}
						customerUserErrors {
						  code
						  field
						  message
						}
					  }            
				}
			`;

			var requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/graphql',
							'Accept': 'application/json',
							'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
				body: query
			};

			const response = await fetch('https://indusfoods-us.myshopify.com/api/2020-07/graphql', requestOptions );
			tokenJson = await response.json();
			//console.log(tokenJson);
		    if (tokenJson) {
			 const accessToken = tokenJson.data.customerAccessTokenCreate.customerAccessToken.accessToken;	
			 return accessToken	
			}
		}
		else {
			return undefined;
		}
  } catch (error) {
    console.log(
      "Got error while trying to get customer token ",
      error
    );
    return undefined;
  }
};*/

/*export const getCustomer = async () => {
  try {
	const customerAccessToken = await getCustomerToken();
	if (customerAccessToken) {
		const query = `{customer(customerAccessToken:"${customerAccessToken}") {
				  id
				  lastIncompleteCheckout {
				    id
				    webUrl
				  }
				}}
		`;	
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/graphql',
						'Accept': 'application/json',
						'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
			body: query
		};			
		const response = await fetch(`https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2020-10/graphql`, requestOptions );
		const customerData = await response.json();
		return customerData.data.customer;		
	}
	else {
		return undefined;
	}
  } catch (error) {
    console.log(
      "Got error while trying to get customer details ",
      error
    );
    return undefined;
  }
};*/

export const getCheckout = async (checkoutId) => {
  try {
	const query = `{node(id:"${checkoutId}") {
			  id
			... on Checkout {
				  id
				  ready
				  completedAt 
				  }
			}}
	`;	
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/graphql',
					'Accept': 'application/json',
					'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
		body: query
	};			
	const response = await fetch(`https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2020-10/graphql`, requestOptions );
	const checkoutData = await response.json();
	return checkoutData.data.node;		
  } 
  catch (error) {
    console.log(
      "Got error while trying to get checkout details ",
      error
    );
    return undefined;
  }
};